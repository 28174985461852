import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage1 from '@images/presse/presse-huettn-1-preview.jpg'
import pressePreviewImage2 from '@images/presse/presse-huettn-2-preview.jpg'
import presseImage1 from '@images/presse/presse-huettn-1-download.jpg'
import presseImage2 from '@images/presse/presse-huettn-2-download.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`

const PressePage = () => {
  //const presseImage1 = '/download/ERDINGER_Brauhaus_Bad_Reichenhall_Pressemeldung.jpg';
  //const presseImage2 = '/download/ERDINGER_Brauhaus_Bad_Reichenhall_Pressemeldung.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Doppel-Premiere auf dem Herbstfest in Erding
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Erste ERDINGER Doppelstock-Hütt’n mit den neuen Festwirten David Ritter und Manfred Kolbeck
                  </strong>
                </p>
                <p>
                  Erding, Juni 2023<br />
                  Zünftig gefeiert wird auf dem Herbstfest in Erding schon immer – in diesem Jahr 
                  setzt der ERDINGER Weißbräu sogar noch einen drauf und errichtet eine Doppelstock-Hütt’n. 
                  Die legendäre Party-Stimmung aus der ehemaligen Urweisse-Hütt’n gibt’s dieses Jahr hoch zwei! 
                  Biergarten, zwei Außenbalkone und eine zweite Etage – das erwartet die Gäste der neuen 
                  ERDINGER Hütt’n, die am Eingang zum Festplatz an der Schützenstraße stehen wird. 
                  Mit 60 Sitzplätzen im Biergarten und 250 Sitzplätzen sowie 230 Stehplätzen innen bietet sie Platz 
                  für bis zu 540 Personen.
                </p>
                <p>
                  „In rund zwei Monaten, am 25. August, heißt es in Erding wieder: ‚Ozapt is!‘. Das 81. Herbstfest in 
                  Erding verspricht zehn Tage zünftige Gaudi, mitreißende Atmosphäre und wahre Lebensfreude“, 
                  erläutert Josef Westermeier, Geschäftsführer Marketing und Vertrieb ERDINGER Weißbräu, und fügt 
                  hinzu: „Mit der neuen ERDINGER Hütt’n bieten wir in diesem Jahr ein neues, großartiges Schmankerl 
                  auf dem Festplatz. Dort wird die pure bayerische Lebenslust gefeiert – mit unvergesslichen 
                  Hütt’n-Partys, deftigem Abendessen und sonntags jeweils um 11 Uhr mit einem gemütlichen 
                  Weißwurstfrühstück.“
                </p>
                <p>
                  Neue Hütt’n gleich neue Festwirte: Für den Betrieb der Hütt’n hat sich die Privatbrauerei 
                  ERDINGER Weißbräu für eine Zusammenarbeit mit David Ritter und Manfred Kolbeck entschieden. Mit dem 
                  Sommergarten in unmittelbarer Nähe zum Volksfestplatz verfügen sie und ihr Team über optimale 
                  Voraussetzungen für die Bewirtschaftung. Bereits im letzten Jahr haben David Ritter und Manfred 
                  Kolbeck die Urweisse-Hütt’n bei den Schwedenspielen in Erding betrieben und dabei gezeigt, dass sie 
                  die perfekten Partner für Veranstaltungen sind und genau wissen, worauf es ankommt. „Wir freuen uns 
                  sehr über die erneute Zusammenarbeit mit ERDINGER Weißbräu und die Möglichkeit, nun auch direkt auf 
                  dem Herbstfest als Festwirte der wunderschönen Doppelstock-Hütt'n den Gästen großartige Stimmung zu 
                  bieten. Die Besucher können sich auf eine sensationelle Atmosphäre, mitreißende Live-Musik und 
                  fetzige Hütt'n-Gaudi freuen“, berichten David Ritter und Manfred Kolbeck stolz. Beste Stimmung 
                  garantieren Gerry & Gary, Guten A-Band, da Rocka & da Waitla und zahlreiche weitere Acts, die 
                  jeweils ab 17 Uhr aufspielen. Von 22 Uhr bis 23:30 Uhr heizt dann ein DJ ordentlich ein. Und auch 
                  für das leibliche Wohl ist gesorgt: Es gibt köstliche Brotzeitbrettl und bayerische Schmankerl im 
                  Reindl. ERDINGER Bierspezialitäten und eine breite Auswahl an weiteren Getränken erfrischen 
                  durstige Kehlen.
                </p>
                <p>
                  Tische können unter <a tw="font-bold underline"
                    href="https://www.erdinger-huettn.de/"
                    className="text-green"
                    target="_blank"
                  >
                    erdinger-hüttn.de
                  </a> während des Herbstfestes täglich ab 19 Uhr reserviert werden. 
                  Die Reservierungen erfolgen immer tischweise für je 7 Personen. Geöffnet ist die ERDINGER Hütt’n 
                  Montag bis Samstag ab dem frühen Nachmittag und sonntags zum Weißwurstfrühstück zusätzlich von 11 
                  bis 14 Uhr. Jeder ist herzlich zum Mitfeiern eingeladen.
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div tw="flex flex-col items-center">
                  <a 
                      href={presseImage2} 
                      download
                      target="_blank"
                    >
                      <img src={pressePreviewImage2} alt="Illustration der ERDINGER Hütt'n auf dem Herbstfest in Erding - Bildquelle: ERDINGER Weißbräu" />
                  </a>
                  <ImageSource>Illustration der ERDINGER Hütt'n auf dem Herbstfest in Erding - Bildquelle: ERDINGER Weißbräu</ImageSource>
                </div>
              </ContentImageWrapper>
              <ContentImageWrapper>
                <div tw="flex flex-col items-center">
                  <a 
                      href={presseImage1} 
                      download
                      target="_blank"
                    >
                      <img src={pressePreviewImage1} alt="Bei der Pressekonferenz zur Vorstellung der ERDINGER Hütt'n (v.l.): Josef Westermeier (Geschäftsführer Marketing und Vertrieb ERDINGER Weißbräu), die neuen Festwirte Manfred Kolbeck und David Ritter sowie Wolfgang Kuffner (Gesamtleitung Marketing ERDINGER Weißbräu) - Bildquelle: Hans Moritz" />
                  </a>
                  <ImageSource>Bei der Pressekonferenz zur Vorstellung der ERDINGER Hütt'n (v.l.): Josef Westermeier (Geschäftsführer Marketing und Vertrieb ERDINGER Weißbräu), die neuen Festwirte Manfred Kolbeck und David Ritter sowie Wolfgang Kuffner (Gesamtleitung Marketing ERDINGER Weißbräu) - Bildquelle: Hans Moritz</ImageSource>
                </div>
              </ContentImageWrapper>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
